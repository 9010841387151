import { ButtonComponent } from "@syncfusion/ej2-react-buttons";
import { DialogComponent } from "@syncfusion/ej2-react-popups";
import createDecorator from "final-form-calculate";
import { Component, Fragment } from "react";
import { FormRenderProps } from "react-final-form";
import RAFForm, { Condition } from "../../../RAFComponents/Inputs/RAFForm";
import RAFAttributeRelatedListProvider from "../../../RAFComponents/Providers/RAFAttributeRelatedListProvider";
import { createInstance } from "../../../RAFComponents/Utility/FormUtility";
import {
  showSuccessToast,
  showWarningToast,
} from "../../../RAFComponents/Utility/RAFToastComponent";
import {
  getSessionStorage,
  hideProgress,
  isCurrentTenantCareESIOPartner,
  setSessionStorage,
  showProgress,
} from "../../../RAFComponents/helpers/AppHelper";
import RAFPermissionRender from "../../../RAFComponents/helpers/PermissionHelper";
import {
  IDialogProps,
  IsNullOrWhiteSpace,
  RAFFormComponentProps,
  deepEqual,
  isNotNullAndUndefined,
} from "../../../RAFComponents/helpers/utils";
import { RAFViewRow } from "../../../RAFComponents/models/Common/RAFViewRow";
import ACDatePicker from "../../../components/shared/ACFormFields/ACDatePicker";
import ACDropdown from "../../../components/shared/ACFormFields/ACDropdown";
import ACLookup from "../../../components/shared/ACFormFields/ACLookup";
import ACTextArea from "../../../components/shared/ACFormFields/ACTextArea";
import ACTextBox from "../../../components/shared/ACFormFields/ACTextBox";
import { TicketConstants } from "../../../constants/ActiveContact/ACPermissionConstants";
import { RAFLayout, StorageKey } from "../../../constants/Common/Constants";

import { BusinessProcessRow } from "../../../RAFMaster/RMModules/BusinessProcess/Process/BusinessProcessRow";
import { BusinessProcessStepTemplateRow } from "../../../RAFMaster/RMModules/BusinessProcess/StepTemplate/BusinessProcessStepTemplateRow";
import { BusinessProcessTemplateRow } from "../../../RAFMaster/RMModules/BusinessProcess/Template/BusinessProcessTemplateRow";
import {
  RetrieveBusinessProcessTemplate,
  StartProcessAndSaveProcessSteps,
} from "../../../RAFMaster/RMModules/BusinessProcess/helpers/ProcessHelper";
import { RAFEntityName } from "../../../constants/Common/EntityConstants";
import {
  RAFBPStatus,
  RAFDeadlineCalculation,
  RAFExecutionOrder,
  RAFPageType,
} from "../../../constants/Common/RMConstants";
import RAFEntityProvider from "../../Common/Providers/RAFEntityProvider";
import RAFObjectContextProvider, {
  RAFObjectContext,
} from "../../Common/Providers/RAFObjectContextProvider";
import RAFPageLayoutProvider from "../../Common/Providers/RAFPageLayoutProvider";
import RAFPageLayout from "../../Common/RAFPageLayout";
import CreateAccount from "../Account/CreateAccount";
import ACContactLookup from "../Contact/ACContactLookup";
import { ContactRow } from "../Contact/ContactRow";
import CreateContact from "../Contact/CreateContact";
import { UserRow } from "../User/UserRow";
import { TicketRow } from "./TicketRow";
import RAFLookUpMUI from "../../../RAFComponents/Inputs/RAFLookUpMUI";

interface IProps {
  accountUID?: string;
  contactUID?: string;
  account?: string;
  contact?: string;
  onProcessStart?: (bpRow) => void;
  bpTemplate?: BusinessProcessTemplateRow;
  objectData?: string;
  initialValues?: any;
}

interface IState {
  isActive?: boolean;
  ticketRow: TicketRow;
  bpTemplate?: BusinessProcessTemplateRow;
  showStartProcessContent?: boolean;
  relatedTo?: string;
  relatedToType?: string;
  relatedToUID?: string;
}

class CreateTicket extends Component<
  IProps & IDialogProps & RAFFormComponentProps,
  IState
> {
  private moduleName: string = createInstance(TicketRow).getClassName();
  public rafForm: FormRenderProps | null;
  public processrafForm: FormRenderProps | null;
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      isActive: false,
      ticketRow: null,
      bpTemplate: {},
      showStartProcessContent: false,
      relatedTo: null,
      relatedToType: null,
      relatedToUID: null,
    };
  }

  componentDidMount = () => {
    this._isMounted = true;
    this.refresh();
  };

  componentDidUpdate = (prevProps) => {
    if (!deepEqual(prevProps.isActive, this.props.isActive)) {
      if (this._isMounted) {
        this.setState({ isActive: false }, () => {
          this.refresh();
        });
      }
    }
  };

  componentWillUnmount() {
    this._isMounted = false;
  }

  onSaveTicket = (entityId, objectName) => {
    let allViews: RAFViewRow[] = JSON.parse(
      getSessionStorage(StorageKey.allViews_modulename + this.moduleName, true)
    );
    let recentlyCreatedView: RAFViewRow =
      allViews && allViews.find((x) => x.ViewName === "Recently_Created"); //recently_created;
    if (isNotNullAndUndefined(recentlyCreatedView)) {
      setSessionStorage(
        StorageKey.recentViewId_modulename + this.moduleName,
        true,
        JSON.stringify({
          viewId: recentlyCreatedView.UID,
          viewName: recentlyCreatedView.DisplayName,
        })
      );
    }
    if (this._isMounted) {
      this.setState({ isActive: false }, () => {
        if (this.props.onSave) this.props.onSave(entityId, objectName);
      });
    }
  };

  onAccountLookupChanged = (id) => {
    if (
      isNotNullAndUndefined(this.rafForm) &&
      isNotNullAndUndefined(this.rafForm.form) &&
      isNotNullAndUndefined(this.rafForm.form.mutators)
    ) {
      this.rafForm.form.mutators.setValue("AccountUID", id);
      this.rafForm.form.mutators.setValue("ContactUID", null);
      this.rafForm.form.mutators.setValue("Contact", null);
    }
  };

  refresh() {
    if (this.props.isActive) {
      let { bpTemplate } = this.props;
      let ticketRow: TicketRow = new TicketRow();
      if (isNotNullAndUndefined(this.props.initialValues)) {
        ticketRow = this.props.initialValues as TicketRow;
      }
      ticketRow.TicketType = "Problem";
      ticketRow.RequestOrigin = "Internal Request";
      ticketRow.Priority = "Low";
      ticketRow.Status = "New";
      /*ticketRow.Account = this.props.account;
      ticketRow.AccountUID = this.props.accountUID;
      ticketRow.ContactUID = this.props.contactUID;
      ticketRow.Contact = this.props.contact;*/

      if (isNotNullAndUndefined(bpTemplate)) {
        ticketRow.BusinessProcess = bpTemplate.ProcessName;
        ticketRow.BusinessProcessUID = bpTemplate.UID;
      }

      if (this._isMounted) {
        this.setState({ isActive: this.props.isActive, ticketRow, bpTemplate });
      }
    } else {
      if (this._isMounted) {
        this.setState({ isActive: this.props.isActive });
      }
    }
  }

  ticketDecorators = createDecorator({
    field: "Priority",
    updates: {
      Priority: (minimumValue, allValues: TicketRow) =>
        allValues.Priority === "None" ? null : allValues.Priority,
    },
  });

  onSubmitTicket = (
    value,
    saveObject?: (
      objectData?: any
    ) => Promise<{ objectUID?: string; objectName?: string; }>
  ) => {
    let ticketRow: TicketRow = value as TicketRow;
    // let progressDiv = showProgress(`#dlg_create_${this.moduleName}`);
    let url = "Ticket/Save";
    let businessProcess = ticketRow.BusinessProcess;
    let businessProcessUID = ticketRow.BusinessProcessUID;
    /*objValues.BusinessProcess = null;
                objValues.BusinessProcessUID = null;*/
    saveObject(ticketRow)
      .then((response) => {
        // hideProgress(progressDiv);
        if (
          isNotNullAndUndefined(response) &&
          !IsNullOrWhiteSpace(response.objectUID)
        ) {
          if (
            isNotNullAndUndefined(businessProcess) &&
            isNotNullAndUndefined(businessProcessUID)
          ) {
            showSuccessToast("Saved successfully");
            ticketRow.BusinessProcess = businessProcess;
            ticketRow.BusinessProcessUID = businessProcessUID;
            ticketRow.UID = response.objectUID;

            this.setState(
              {
                relatedTo: response.objectName,
                relatedToUID: response.objectUID,
                relatedToType: "ticket",
                ticketRow: ticketRow,
              },
              () => {
                let bpTemplateUID = businessProcessUID;

                RetrieveBusinessProcessTemplate(bpTemplateUID).then(
                  (bpTempateResponse) => {
                    if (
                      isNotNullAndUndefined(
                        bpTempateResponse.ProcessDeadlineCalculation
                      ) &&
                      bpTempateResponse.ProcessDeadlineCalculation ===
                      RAFDeadlineCalculation.PromptUserWhenRun
                    ) {
                      this.startProcessDialogOpen(bpTempateResponse);
                    } else {
                      let progressDiv = showProgress(
                        `#dlg_create_${this.moduleName}`
                      );
                      StartProcessAndSaveProcessSteps(
                        {
                          bpRelatedTo: response.objectName,
                          bpRelatedToType: "ticket",
                          bpRelatedToUID: response.objectUID,
                          objectData: this.props.objectData,
                        },
                        bpTempateResponse
                      ).then((bpResponse) => {
                        hideProgress(progressDiv);
                        this.onProcessStart(
                          bpResponse.bpRow,
                          bpResponse.bpStepTemplateRows,
                          saveObject
                        );
                      });
                    }
                  }
                );
              }
            );
          } else {
            showSuccessToast("Saved successfully");
            let allViews: RAFViewRow[] = JSON.parse(
              getSessionStorage(
                StorageKey.allViews_modulename + this.moduleName,
                true
              )
            );
            let recentlyCreatedView: RAFViewRow =
              allViews &&
              allViews.find((x) => x.ViewName === "Recently_Created"); //recently_created;
            if (isNotNullAndUndefined(recentlyCreatedView)) {
              setSessionStorage(
                StorageKey.recentViewId_modulename + this.moduleName,
                true,
                JSON.stringify({
                  viewId: recentlyCreatedView.UID,
                  viewName: recentlyCreatedView.DisplayName,
                })
              );
            }
            if (this._isMounted) {
              this.setState({ isActive: false }, () => {
                if (this.props.onSave)
                  this.props.onSave(response.objectUID, response.objectName);
              });
            }
          }
        } else {
          showWarningToast("Sorry something went wrong !");
        }
      })
      .catch((error) => error);
  };

  //bp_dialog starts
  private startProcessDialogOpen(bpTempateResponse): void {
    this.setState(
      { showStartProcessContent: true, bpTemplate: bpTempateResponse },
      () => {
        document.body.classList.add("overflow-hidden");
      }
    );
  }

  private startProcessDialogClose(): void {
    this.setState({ showStartProcessContent: false }, () => {
      let allViews: RAFViewRow[] = JSON.parse(
        getSessionStorage(
          StorageKey.allViews_modulename + this.moduleName,
          true
        )
      );
      let recentlyCreatedView: RAFViewRow =
        allViews && allViews.find((x) => x.ViewName === "Recently_Created"); //recently_created;
      if (isNotNullAndUndefined(recentlyCreatedView)) {
        setSessionStorage(
          StorageKey.recentViewId_modulename + this.moduleName,
          true,
          JSON.stringify({
            viewId: recentlyCreatedView.UID,
            viewName: recentlyCreatedView.DisplayName,
          })
        );
      }
      if (this._isMounted) {
        this.setState({ isActive: false }, () => {
          if (this.props.onSave)
            this.props.onSave(this.state.relatedToUID, this.state.relatedTo);
        });
      }
      document.body.classList.remove("overflow-hidden");
    });
  }

  onSubmitProcessTemplate = (
    value,
    saveObject?: (
      objectData?: any
    ) => Promise<{ objectUID?: string; objectName?: string; }>
  ) => {
    let bPTemplateRow: BusinessProcessTemplateRow = value;
    let progressDiv = showProgress("body", true);

    let { relatedTo, relatedToType, relatedToUID } = this.state;
    //StartProcess({ bptemplateId: bPTemplateRow.UID, bpFormData: bPTemplateRow.FormData, bpDuedate: bPTemplateRow.DueDate, bpRelatedTo: relatedTo, bpRelatedToType: relatedToType, bpRelatedToUID: relatedToUID, objectData: this.props.objectData }).then(bpResponse => {
    StartProcessAndSaveProcessSteps(
      {
        bpRelatedTo: relatedTo,
        bpRelatedToType: relatedToType,
        bpRelatedToUID: relatedToUID,
        objectData: this.props.objectData,
      },
      bPTemplateRow
    ).then((bpResponse) => {
      hideProgress(progressDiv);
      this.onProcessStart(
        bpResponse.bpRow,
        bpResponse.bpStepTemplateRows,
        saveObject
      );
    });
  };

  startProcessContent(saveObject) {
    let { bpTemplate } = this.state;
    //const loggedinUserName = msalInstance.currentUserName;
    if (
      this.state.showStartProcessContent &&
      this.state.showStartProcessContent === true
    ) {
      return (
        <div id="startBusinessProcessForm">
          <RAFForm
            type={BusinessProcessTemplateRow}
            initialValues={bpTemplate}
            formRef={(g) => (this.processrafForm = g)}
            layout={RAFLayout.TwoColumnLayout}
            onSubmit={(value) =>
              this.onSubmitProcessTemplate(value, saveObject)
            }
          >
            <div className="dlg-new-style">
              <div className="e-dlg-headerContent justify-content-between">
                <div className="d-flex align-items-center w-100">
                  <div className="e-dlg-header" id="_title">
                    {" "}
                    {bpTemplate.ProcessName}
                  </div>
                </div>
                <ButtonComponent
                  type="button"
                  cssClass="primary-custom-button"
                  iconCss="fas fa-xmark"
                  onClick={() => this.startProcessDialogClose()}
                ></ButtonComponent>
              </div>
              <div className="e-dlg-body-content pt-3 h-100 overflow-auto">
                {/*<div className="business-process-item mb-2">
                                        <div className="d-flex" style={{ fontWeight: 400 }}>
                                            <span className="pe-1" style={{ fontWeight: 500 }}>{bpTemplate.ProcessName}</span>
                                            <span className="pe-1"> started by <span className="pe-1" style={{ fontWeight: 500 }}>{(isNotNullAndUndefined(bpTemplate.Initiator) ? bpTemplate.Initiator : loggedinUserName)}</span></span>
                                            <span className="pe-1"> on <span style={{ fontWeight: 500 }}>{moment(new Date()).format("DD/MM/YYYY")}</span></span>
                                        </div>
                                    </div>*/}
                <Condition when="ProcessDeadlineCalculation" is={"Not set"}>
                  <ACDatePicker<BusinessProcessTemplateRow>
                    field="DueDate"
                    label="Do you want to set deadline?"
                  />
                </Condition>
                <Condition when="ProcessDeadlineCalculation" is={null}>
                  <ACDatePicker<BusinessProcessTemplateRow>
                    field="DueDate"
                    label="Do you want to set deadline?"
                  />
                </Condition>
              </div>
              <div className="e-dlg-footerContent ">
                <ButtonComponent
                  type="submit"
                  className="me-2"
                  cssClass="e-flat e-info"
                >
                  Start
                </ButtonComponent>
                <ButtonComponent
                  type="button"
                  cssClass="e-flat"
                  onClick={() => this.startProcessDialogClose()}
                >
                  Cancel
                </ButtonComponent>
              </div>
            </div>
          </RAFForm>
        </div>
      );
    } else {
      return <div></div>;
    }
  }

  onProcessStart = (
    bpRow: BusinessProcessRow,
    bpStepTemplateRows: BusinessProcessStepTemplateRow[],
    saveObject?: (
      objectData?: any
    ) => Promise<{ objectUID?: string; objectName?: string; }>
  ) => {
    if (isNotNullAndUndefined(bpRow)) {
      let ticketRow: TicketRow = this.state.ticketRow as TicketRow;
      ticketRow.ProcessStatus =
        bpRow.ExecutionOrder === RAFExecutionOrder.AnyOrder
          ? RAFBPStatus.InProgress
          : bpStepTemplateRows && bpStepTemplateRows[0].Title;
      ticketRow.BusinessProcess = bpRow.ProcessName;
      ticketRow.BusinessProcessUID = bpRow.UID;
      let progressDiv = showProgress("body", true);
      saveObject(ticketRow)
        .then((response) => {
          hideProgress(progressDiv);
          if (
            isNotNullAndUndefined(response) &&
            !IsNullOrWhiteSpace(response.objectUID)
          ) {
            if (this._isMounted) {
              this.setState({ isActive: false }, () => {
                if (this.props.onProcessStart)
                  this.props.onProcessStart(ticketRow.UID);
                else if (this.props.onSave)
                  this.props.onSave(
                    this.state.relatedToUID,
                    this.state.relatedTo
                  );
              });
            }
          } else {
            showWarningToast("Sorry something went wrong !");
          }
        })
        .catch((error) => error);
    }
  };
  //bp_dialog end

  render() {
    let { ticketRow, isActive } = this.state;

    if (isNotNullAndUndefined(ticketRow) && isActive) {
      ticketRow.LoggedDate = new Date();
      return (
        isActive && (
          <div className="h-100" id={`raf_dlg_Outer_Div_${this.moduleName}`}>
            <RAFEntityProvider moduleName={this.moduleName}>
              <RAFAttributeRelatedListProvider moduleName={this.moduleName}>
                <RAFPageLayoutProvider rafPageType={RAFPageType.Create}>
                  <RAFObjectContextProvider
                    moduleName={this.moduleName}
                    progressDivId={`#raf_dlg_Outer_Div_${this.moduleName}`}
                  >
                    <RAFObjectContext.Consumer>
                      {({ /*objectId, rafObject,*/ saveObject }) => {
                        return (
                          <Fragment>
                            <RAFForm
                              type={TicketRow}
                              initialValues={ticketRow}
                              formRef={(g) => (this.rafForm = g)}
                              layout={RAFLayout.TwoColumnLayout}
                              //onSave={this.onSaveTicket}
                              //onSubmit={this.onSubmitTicket}
                              onSubmit={(value) =>
                                this.onSubmitTicket(value, saveObject)
                              }
                              decorators={[this.ticketDecorators]}
                              className="h-100"
                            >
                              <div className="e-dlg-content-outer">
                                <div className="e-dlg-body-content">
                                  <RAFPageLayout
                                    defaultComponent={
                                      <div>
                                        <ACTextBox<TicketRow>
                                          field="Subject"
                                          label="Subject"
                                          required
                                          placeholder="Subject"
                                        />
                                        <RAFLookUpMUI<TicketRow>
                                          field="Account"
                                          label="Company Name"
                                          placeholder="Select Company Name"
                                          required={
                                            !isCurrentTenantCareESIOPartner()
                                          }
                                          //type={AccountRow}
                                          url={"Account/Lookup"}
                                          moduleName={RAFEntityName.Account}
                                          showCreateButton
                                          createform={CreateAccount}
                                          createformdefaultfield={"AccountName"}
                                          onChanged={(value, uid) =>
                                            this.onAccountLookupChanged(uid)
                                          }
                                          selectedField={"AccountName"}
                                          SearchCreateOptionMode={"Footer"}
                                        />
                                        <ACContactLookup<TicketRow>
                                          field="Contact"
                                          label="Contact Name"
                                          placeholder="Select Contact Name"
                                          required
                                          //type={ContactRow}
                                          showCreateButton
                                          url={"Contact/Lookup"}
                                          moduleName={RAFEntityName.Contact}
                                          //account={ticketRow.Account} accountUID={ticketRow.AccountUID}
                                          createform={CreateContact}
                                          createformdefaultfield={"FirstName"}
                                          //createformaccountfield={"Account"}
                                          //createformaccountUIDfield={"AccountUID"}
                                          fieldName="Account"
                                          selectedField={"ContactName"}
                                          //createFormInitialValues={[{ key: "Account", value: ticketRow.Account }, { key: "AccountUID", value: ticketRow.AccountUID }]}
                                          SearchCreateOptionMode={"Footer"}
                                        />
                                        <ACDropdown<TicketRow>
                                          field="TicketType"
                                          label="Type"
                                          placeholder="Select Type"
                                          isColorOption
                                          uitype="colorpicker"
                                          createOptionMode={"Footer"}
                                          mode="discView"
                                          moduleName={this.moduleName}
                                          required
                                        />
                                        <ACDropdown<TicketRow>
                                          field="Status"
                                          label="Status"
                                          placeholder="Select Status"
                                          isColorOption
                                          uitype="colorpicker"
                                          createOptionMode={"Footer"}
                                          moduleName={this.moduleName}
                                          required
                                        />
                                        <ACDropdown<TicketRow>
                                          field="Substatus"
                                          label="Sub-status"
                                          placeholder="Select Sub-status"
                                          isColorOption
                                          uitype="colorpicker"
                                          createOptionMode={"Footer"}
                                          moduleName={this.moduleName}
                                          required
                                        />
                                        <ACDatePicker<TicketRow>
                                          field="LoggedDate"
                                          label="Logged Date"
                                          required
                                          placeholder="Logged Date"
                                        />
                                        <ACDropdown<TicketRow>
                                          field="RequestOrigin"
                                          label="Request Origin"
                                          placeholder="Select Request Origin"
                                          required
                                          isColorOption
                                          mode="discView"
                                          uitype="colorpicker"
                                          createOptionMode={"Footer"}
                                          moduleName={this.moduleName}
                                        />
                                        <ACDropdown<TicketRow>
                                          field="Priority"
                                          label="Priority"
                                          placeholder="Select Priority"
                                          isColorOption
                                          mode="discView"
                                          uitype="colorpicker"
                                          createOptionMode={"Footer"}
                                          moduleName={this.moduleName}
                                        />
                                        {/*<ACDropdown<TicketRow> field="RequestOrigin" label="Request Origin" placeholder="Select Request Origin" required >
                                <RAFChoiceOption label="Phone" value="Phone" />
                                <RAFChoiceOption label="Email" value="Email" />
                                <RAFChoiceOption label="Web" value="Web" />
                                <RAFChoiceOption label="Internal Request" value="Internal Request" />
                            </ACDropdown>
                            <ACDropdown<TicketRow> field="Priority" label="Priority" placeholder="Select Priority" >
                                <RAFChoiceOption label="High" value="High" />
                                <RAFChoiceOption label="Medium" value="Medium" />
                                <RAFChoiceOption label="Low" value="Low" />
                                <RAFChoiceOption label="Urgent" value="Urgent" />
                                <RAFChoiceOption label="Immediate" value="Immediate" />
                            </ACDropdown>*/}
                                        <ACTextArea<TicketRow>
                                          field="Description"
                                          label="Description of Problem"
                                        />
                                        {/*<ACTextBox<TicketRow> field="TicketId" label="Ticket No" placeholder="Ticket No" />*/}
                                        <ACLookup<TicketRow>
                                          field="InternalContact"
                                          label="Internal Contact"
                                          placeholder="Select Internal Contact"
                                          type={UserRow}
                                          url={"User/Lookup"}
                                          moduleName={RAFEntityName.User}
                                          showFullList={false}
                                        />
                                        <ACLookup<TicketRow>
                                          field="Assignee"
                                          label="Assign to"
                                          placeholder="Select Assign to"
                                          type={UserRow}
                                          url={"User/Lookup"}
                                          moduleName={RAFEntityName.User}
                                          showFullList={false}
                                        />
                                        <ACTextArea<TicketRow>
                                          field="AdditionalInfo"
                                          label="Additional Info"
                                        />
                                        {/*<RAFExtraInfo<TicketRow> field="ExtraInfoJson" label="Extra Info" />*/}
                                      </div>
                                    }
                                  />
                                </div>
                                <div className="e-dlg-footerContent">
                                  <RAFPermissionRender
                                    permissionName={
                                      TicketConstants.TicketCreate
                                    }
                                  >
                                    <ButtonComponent
                                      type="button"
                                      className="me-2"
                                      cssClass="e-flat e-info"
                                      onClick={() => {
                                        this.rafForm &&
                                          this.rafForm.form.submit();
                                      }}
                                      id={`btn_save_create_${this.moduleName}`}
                                    >
                                      Save
                                    </ButtonComponent>
                                  </RAFPermissionRender>
                                  <ButtonComponent
                                    type="button"
                                    cssClass="e-flat"
                                    onClick={this.props.onClose}
                                    id={`btn_cancel_create_${this.moduleName}`}
                                  >
                                    Cancel
                                  </ButtonComponent>
                                </div>
                              </div>
                            </RAFForm>
                            {this.state.showStartProcessContent && (
                              <DialogComponent
                                visible={this.state.showStartProcessContent}
                                cssClass="rightDialog createEditForm full-height form-center-dialog"
                                id="startBusinessProcessDialog"
                                content={this.startProcessContent.bind(
                                  this,
                                  saveObject
                                )}
                                isModal
                                target="body"
                                closeOnEscape={false}
                                close={this.startProcessDialogClose.bind(this)}
                              ></DialogComponent>
                            )}
                          </Fragment>
                        );
                      }}
                    </RAFObjectContext.Consumer>
                  </RAFObjectContextProvider>
                </RAFPageLayoutProvider>
              </RAFAttributeRelatedListProvider>
            </RAFEntityProvider>
          </div>
        )
      );
    } else {
      return <div></div>;
    }
  }
}

export default CreateTicket;
